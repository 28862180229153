import {useState, useEffect} from 'react';
import {Container, Paper, Box, Typography} from '@mui/material';

const Index = () => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
    }, [loaded]);

    return (<Container maxWidth="sm" sx={{mt:2}}>
        <Paper sx={{p:4}}>
            <Box display="flex" justifyContent="center">
                <Typography variant="h3">24x7</Typography>
            </Box>
        </Paper>
        </Container>);
}

export default Index;
