const endpoint = 'https://api.24x7.cl/url/';

export const post = (shorturl) => {
    const options = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({shorturl: shorturl}),
    };
    return fetch(endpoint, options).then((response) => response.json());
}
