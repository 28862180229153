import {useState, useEffect} from 'react';
import {Container, Paper, Box} from '@mui/material';
import {useParams} from 'react-router-dom';
import {post} from './api';
import Loader from './Loader';

const Index = () => {
    const [loaded, setLoaded] = useState(false);
    const [loader, setLoader] = useState(false);
    const {shorturl} = useParams();
    const [error, setError] = useState(undefined);

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
            setLoader(true);
            post(shorturl).then(data => {
                if(data.hasOwnProperty('target')) {
                    document.location.replace(data.target);
                } else {
                    setError(data.message);
                }
                setLoader(false);
                console.log(data);
            })
        }
    }, [loaded, loader, shorturl, error]);

    return (<Container maxWidth="sm" sx={{mt:2}}>
        <Loader loader={loader} />
        {!loader && (<Paper sx={{p:4}}>
            <Box display="flex" justifyContent="center">
                {error === undefined ? (<>Redirigiendo...</>) : (<>{error}</>)}
            </Box>
        </Paper>)}
        </Container>);
}

export default Index;
