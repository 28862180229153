// common
import NotFound from './components/NotFound';
import Layout from './components/Layout';
import {default as RedirIndex} from './components/Redir/Index';
import {default as HomeIndex} from './components/Home/Index';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
    {   
        path: "not-found",
        element: <NotFound />,
    },
    {   
        path: "",
        element: <Layout />,
        children: [
            {   
                path: "",
                element: <HomeIndex />,
            },
            {   
                path: ":shorturl",
                element: <RedirIndex />,
            },
        ]
    },
    {
        path: "*",
        element: <NotFound />
        }
]);

const App = () => {
    return (
        <RouterProvider router={router} />
        );
}

export default App;
